<template lang="pug">
nav.bread-crumb
  ol.bread-crumb__list
    router-link.bread-crumb__list__item(:to='parentRoute.path') {{ parentRoute.label }}
    li.bread-crumb__list__item
      img(src='@/assets/chevron-right.svg', alt='Chevron droite')
      | {{ currentRoute.label }}
</template>

<script>
export default {
  name: 'BreadCrumb',
  props: {
    parentRoute: {
      type: Object,
      required: true
    },
    currentRoute: {
      type: Object,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/vars.scss';
@import '@/styles/colors.scss';

.bread-crumb {
  font-family: 'Libre Franklin', sans-serif;
  margin: 1rem $mainHorizontalPadding;

  @include medium-screen {
    margin: 1rem $mainHorizontalPaddingMedium;
  }
  @include large-screen {
    margin: 1rem $mainHorizontalPaddingLarge;
  }

  &__list {
    display: flex;
    align-items: center;
    &__item {
      margin: 0 0.3rem;
      text-decoration: none;
      color: $darkGrey;
      font-weight: 600;

      img {
        margin-bottom: -0.2rem;
        margin-right: 0.5rem;
      }
    }
  }
}
</style>
